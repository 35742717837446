body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  white-space: break-spaces;
}

html,
body {
  margin: 0;
}

body {
  text-align: center;
  padding: 0px;
  font-family: Lato, sans-serif;
}

h1,h2,h3,h4,h5,h6{
  font-weight: 700;
}

img {
  max-width: 100%;
}
.relative{
  position: relative;
}
.row{
  margin-bottom: 2em
}

.imgcontainer {
  max-width: 600px;
  margin: 0 auto;
}

.logo.icon {
  width: 48px;
  vertical-align: middle;
}
.illust01{
  height: 200px;
  margin-top: -2em;
  max-width: unset;
}
.illust02{
  height: 200px;
  max-width: unset;
  position: absolute;
  margin-top: -1em;
  left: 0;

}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  padding: .5em 3em;
  box-sizing: border-box;
  background-color: #eff3f3;
  color: #32105e;
  text-shadow: none;
  box-shadow: 0 4px 16px -12px #18072e;
  z-index: 9;
}

.navigation .identity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heroimage{
  height: 400px;
  display: block;
  background: #45B4BB url('main_hero_image.png');
  background-size: cover;
  background-position: center center;
}

.content {
  border-top: 10px solid #45B4BB;
  padding: 60px;
  overflow: hidden;
}

.contentdiv {
  padding: 40px 0;
}

.editordiv {
  border-top: 10px dashed #45B4BB;
  padding: 40px 0 0;
}

.contentdiv,
.editordiv {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.content.main {
  background: #32105e;
  text-align: left;
  color: #FFF;
  text-shadow: 1px 1px #18072e;
  background-position: top center;
  background-size: 150% auto;
  background-repeat: no-repeat;
  position: relative;
}
.content.main:after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: linear-gradient( #32105e88 , #32105e 30% ) transparent no-repeat;
  width: auto;
  height: auto;
  content: '';
}


.content.main h1 {
  font-size: 450%;
}

.content.main input,
.content.main button,
.content.main textarea {
  display: block;
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
}
label{
  display: inline-block;
  padding: 1em 0em .5em;
  font-size: 80%;
}
.content.main button{
  margin-top: 2em;
  background-color: #45B4BB;
  border: none;
  border-radius: .5em;
  cursor: pointer;
}
.content.main button:hover{
  background-color: #2f8f96;
}

.content.main textarea {
  height: 8em;
}

.content.main .contentimage {
  width: 30vw;
  max-width: 50%;
  float: right;
  padding: 0 0 5% 5%;
}

.footer {
  background-color: #f5f8ff;
  padding: 60px;
  margin-top: 4em;
}

.linkbuttons-ul {
  list-style-type: none;
  padding: 1em 0;
}

.linkbuttons-ul li {
  list-style-type: none;
  margin: .5em 0;
}

.linkbuttons-ul li a.button {
  display: block;
  background: #45B4BB;
  color: #FFF;
  list-style-type: none;
  padding: .5em 1em;
  margin: .5em 0;
  border-radius: 1em;
  font-size: 120%;
  text-decoration: none;
  transition: all .5s;
}

.linkbuttons-ul li a.button:hover {
  background: #2f8f96;
}

@media all and (max-width:720px) {
  .navigation {
    padding: .5em 1.5em .5em 1em;
  }
  .content.main h1{
    font-size: 350%;
  }
  .content,
  .footer {
    padding: 40px;
  }
}

.hide{
  display: none !important;
}